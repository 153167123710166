import {React, useState} from 'react'
import { Modal, Image, Row, Col } from 'react-bootstrap'
import { IoClose } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import './CategoryModal.css'

const CategoryContainer = (props) => {

  const categories = props.categories;
  console.log(categories);

  const bg = {
    1 : '#955251',
    2 : '#B565A7',
    3 : '#009B77',
    4 : '#DD4124',
    5 : '#D65076',
    6 : '#45B8AC',
    7 : '#EFC050',
    8 : '#5B5EA6',
    9 : '#9B2335',
    0 : '#DFCFBE'
  }

  return (
    <>
  <Modal show={props.data ? true : false} fullscreen={true} onHide={props.onClick}>
    <Modal.Header closeButton>
      <Modal.Title>Categories</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Row>
      {categories.map((category, index) => (
        <Row>
          <Col onClick={()=>props.categoryFunctionProps.handleCategoryClick(category)} className="cursor_pointer" key={index}  xs={4} sm={3} md={2} lg={1}>
            <div  className='d-flex flex-column justify-content-center rounded border' style={{borderRadius: '8px' }}>
              <div className="cateory-img-container text-center">
                <Image className="_obj-fit-cover h-100 w-100" src={category.image}/>
              </div>
              <span style={{ backgroundColor: bg[index % 10] }} className="text-center m-0 p-0 text-light">{category.name}</span>
            </div>
          </Col>
            {category.subCategories.map((subcategory, subIndex) => (
              <Col onClick={()=>props.categoryFunctionProps.handleCategoryClick(subcategory)} className="cursor_pointer" key={index}  xs={4} sm={3} md={2} lg={1}>
              <div  className='d-flex flex-column justify-content-center rounded border' style={{borderRadius: '8px' }}>
                <div className="cateory-img-container text-center">
                  <Image className="_obj-fit-cover h-100 w-100" src={category.image}/>
                </div>
                <span style={{ backgroundColor: bg[subIndex+index % 10] }} className="text-center m-0 p-0 text-light">{subcategory.name}</span>
              </div>
              </Col>
            ))}
        </Row>
      ))}
    </Row>
    </Modal.Body>
  </Modal>
  </>
  )
}

export default CategoryContainer