import { createSlice } from "@reduxjs/toolkit";

import {
    addOrder,
    resetOrderApiResponseFlag,
    createPayment
} from "./thunk"

export const initialState = {
    orderStatusCode: null,
    paymentStatusCode: null,
    orderInfo: {},
    error: {},
    orderMsgResponse: {},
    paymentResponse: {},
    orderAddUpdate: {},
};

const orderSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // Get Menu
        builder.addCase(addOrder.fulfilled, (state, action) => {
            state.orderStatusCode = action.payload.statusCode;
            state.orderMsgResponse = action.payload.message;
            state.orderInfo = action.payload.data
        })
        builder.addCase(addOrder.rejected, (state, action) => {
            state.error = action.payload || null;
            state.orderStatusCode = 400;
            state.orderInfo = {}
        })

        // Get Menu
        builder.addCase(createPayment.fulfilled, (state, action) => {
            state.paymentStatusCode = action.payload.statusCode;
            state.paymentResponse = action.payload;
            state.orderMessage = action.payload.message;
        })
        builder.addCase(createPayment.rejected, (state, action) => {
            state.error = action.payload || null;
            state.paymentStatusCode = 400;
            state.paymentResponse = action.payload;
        })

        // Menu Msg Res
        builder.addCase(resetOrderApiResponseFlag.fulfilled, (state, action) => {
            state.orderMsgResponse = { message: "" };
            state.orderStatusCode = null;
            state.orderInfo = {}
        })
    },
})

export default orderSlice.reducer;